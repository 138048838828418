export enum LocalStorageKeys {
  CLOSE = 'close',
  TRADING_VIEW_CHART_PROPERTIES = 'tradingview.chartproperties',
  LOCAL_STORAGE_USER_TYPE_KEY = 'userType',
  EXTERNAL_DEPOSIT = 'external-deposit',
  USING_MINIPAY = 'usingMiniPay',
  SENTRY_USER = 'sentry_user',
  FIREBASE_TOKEN = 'firebaseToken',
  STUDIES = 'studies_',
  LEVERAGE = 'leverage',
  SELECTED_POOL = 'selectedPool',
}
