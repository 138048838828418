import { wsClient } from '@/services/graphql'
import { CANDLE_UPDATE_SUBSCRIPTION } from '@/services/graphql/subscriptions/orders'
import { Candle } from '@/graphql/generated/graphql-request'
import type { ResolutionString } from 'public/tradingview/charting_library'

// Import the Redux store
import { store } from '@/store' // Adjust the path to where your store is defined

// Assuming setLastChartPrice is an action creator
import { setLastChartPrice } from '@/store/chartPriceSlice'
import { setCandles } from '@/store/candleSlice'
import { queryClient } from '@/services/api'
import { RESOLUTION_MAP_TV_GRAPHQL } from '@/utils/constants'

export function subscribeOnStream(symbolInfo: FixMe, resolution: FixMe, onRealtimeCallback: FixMe) {
  const mappedResolution = RESOLUTION_MAP_TV_GRAPHQL[resolution as ResolutionString]
  console.log('subscribeOnStream resolution', mappedResolution)

  return wsClient.subscribe(
    {
      variables: {
        resolution: mappedResolution,
        marketId: symbolInfo.ticker,
      },
      operationName: 'CandlesUpdated',
      query: CANDLE_UPDATE_SUBSCRIPTION,
    },
    {
      next: ({ data }: { data: { candlesUpdated: Candle[] } | null }) => {
        if (!data || data.candlesUpdated.length === 0) return

        if (data.candlesUpdated && data.candlesUpdated.length == 1) {
          // Dispatch action using the store directly, Record<string, number>
          store.dispatch(setLastChartPrice({ ticker: symbolInfo.ticker, price: data.candlesUpdated[0].close }))
          store.dispatch(setCandles({ ticker: symbolInfo.ticker, candles: [{ ...data.candlesUpdated[0] }] }))
          // TODO: Add socket candle to store

          return onRealtimeCallback(data.candlesUpdated[0])
        } else {
          queryClient.invalidateQueries({ queryKey: ['CHART', symbolInfo.ticker, resolution] }).finally()
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => {},
    },
  )
}
