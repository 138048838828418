import { ColumnDef } from '@tanstack/react-table'

export interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

export enum AssetBalanceType {
  Available = 'available',
  Locked = 'locked',
  Borrowed = 'borrowed',
  Supplied = 'supplied',
  Total = 'total',
  MaxBorrow = 'maxBorrow',
  LiquidationPrice = 'liquidationPrice',
}
