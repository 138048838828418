import { TvChart } from '@/components/charts/TvChart'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Chart = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (navigate) {
      ;(window as any).navigate = (url: string) => {
        navigate(url)
      }
    }
  }, [navigate])

  return <TvChart candlesOnly />
}
