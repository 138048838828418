import React from 'react'
import { Asset } from '@/graphql/generated/graphql-request'
import { CreateOrUpdateAsset } from '@/components/administration/CreateOrUpdateAsset'

interface CreateOrUpdateAssetModalProps {
  asset: Asset
}

export const UpdateAssetModal = ({ asset }: CreateOrUpdateAssetModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <div>
      {isOpen && (
        <div className={'relative z-40'} onClick={handleClose}>
          <div className={'fixed bg-black/80 w-full h-full top-0 left-0 right-0 bottom-0'}>
            <div
              id={'create-traderbot-modal'}
              className={
                'w-3/4 p-4 bg-panel flex flex-col justify-center items-center border border-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg'
              }
              onClick={(e) => e.stopPropagation()}
            >
              <button onClick={handleClose} className={'absolute top-2 right-2 text-white text-xl'}>
                &times;
              </button>
              <div className={'text-primary text-xl font-semibold mb-6 '}>Update Asset</div>
              <div className={'flex justify-center items-center'}>
                <CreateOrUpdateAsset asset={asset} />
              </div>
            </div>
          </div>
        </div>
      )}

      <button
        type='button'
        onClick={() => setIsOpen(true)}
        className='btn w-full p-1 bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
      >
        Update Asset
      </button>
    </div>
  )
}
