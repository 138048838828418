import Dropdown from '@/components/Dropdown'
import { Link } from 'react-router-dom'
import { SlLogout, SlSettings, SlUser } from 'react-icons/sl'
import { FiHelpCircle } from 'react-icons/fi'

import { Button } from '@/components/ui/button'
import { copyToken } from '@/utils/tokenUtils'
import { useSelector } from 'react-redux'
import { IRootState } from '@/store'
import { useUser } from '@/hooks/useUser'
import useSignOutAndClearStore from '@/hooks/useSignOutAndClearStore'

export const ProfileMenu = () => {
  const signOutAndClearStore = useSignOutAndClearStore()
  const { user } = useUser()
  const isRtl = useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl'
  const photo = user?.photoURL ?? '/assets/images/user-profile.png'

  return (
    <div className='dropdown shrink-0 items-center'>
      <Dropdown
        offset={[0, 8]}
        placement={`${isRtl ? 'bottom-start' : 'bottom-end'}`}
        btnClassName='relative group block items-center h-full'
        button={
          <img
            className='w-5 h-5 rounded-full object-cover saturate-50 group-hover:saturate-100 items-center'
            src={photo}
            alt='userProfile'
          />
        }
      >
        <ul className='text-dark dark:text-white-dark !py-0 w-[230px] font-semibold dark:text-white-light/90'>
          <li>
            <div className='flex items-center px-4 py-4'>
              <img className='rounded-md w-10 h-10 object-cover' src={photo} alt='userProfile' />
              <div className='ltr:pl-4 rtl:pr-4 truncate'>
                <h4 className='text-base'>
                  {user?.name ?? 'User'}
                  <span className='text-xs bg-success-light rounded text-success px-1 ltr:ml-2 rtl:ml-2'>Pro</span>
                </h4>
                <button
                  type='button'
                  className='text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white'
                >
                  {user?.email}
                </button>
              </div>
            </div>
          </li>
          <li>
            <Link to='/user/profile' className='dark:hover:text-white'>
              <SlUser size={24} className='mr-2 text-gray-400' />
              Profile
            </Link>
          </li>
          <li>
            <Link to='/user/settings' className='dark:hover:text-white'>
              <SlSettings size={24} className='mr-2 text-gray-400' />
              Account Settings
            </Link>
          </li>
          <li>
            <Link to='/help' className='dark:hover:text-white'>
              <FiHelpCircle size={24} className='mr-2 text-gray-400' />
              Help
            </Link>
          </li>
          <li className='border-t border-white-light dark:border-white-light/10'>
            <Link to='/auth/sign-in' className='text-danger !py-3' role='button' onClick={() => signOutAndClearStore()}>
              <SlLogout size={24} className='pr-0.5 mr-2 text-danger' />
              Sign Out
            </Link>
          </li>
          <li className={'p-2'}>
            <Button
              className='w-1/6 btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-black border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
              onClick={copyToken}
            >
              Copy token
            </Button>
          </li>
        </ul>
      </Dropdown>
    </div>
  )
}
