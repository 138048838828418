import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'
import { useQuery } from '@tanstack/react-query'
import { TIME_DURATION } from '@/utils/constants'
import { getSdk, Order, OrderState } from '@/graphql/generated/graphql-request'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { orderBy } from 'lodash'

export const useUserOrders = (orderBookId: string) => {
  const user = useFirebaseAuth()

  const { isLoading: loadingOrders, data = [] } = useQuery({
    queryKey: ['USER_ORDERS', orderBookId],
    retry: 0,
    placeholderData: [],
    refetchInterval: TIME_DURATION.MIN, // ReFetch every 30 seconds
    queryFn: () =>
      getSdk(graphQLClient)
        .UserOrderBookOrders({
          orderBookId: orderBookId,
        })
        .then((result) => (result.userOrderBookOrders as Array<Order>) ?? [])
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on useUserOrders'
          createNotification('Error on useUserOrders', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    enabled: !!user?.uid,
  })

  // orderBy by updatedAt
  const openOrders =
    orderBy(
      data?.filter((order) => order.state === OrderState.Created || order.state === OrderState.Partial) ?? [],
      'updatedAt',
      'desc',
    ) ?? []

  const pastOrders =
    orderBy(
      data?.filter(
        (order) =>
          order.state === OrderState.Executed ||
          order.state === OrderState.Canceled ||
          order.state === OrderState.PartialExecuted,
      ) ?? [],
      'updatedAt',
      'desc',
    ) ?? []

  const allOrders = orderBy(data, 'updatedAt', 'desc')
  return { loadingOrders, orders: allOrders, openOrders, pastOrders }
}
