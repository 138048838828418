import { ColumnDef } from '@tanstack/react-table'
import { Asset } from '@/graphql/generated/graphql-request'
import { formatNumber } from '@/utils/numberUtils'
import { PRICE_DECIMALS } from '@/utils/constants'
import { AssetBalanceType } from '@/types/Table'

export const tableColumnsUserBalances: ColumnDef<Asset>[] = [
  {
    accessorKey: 'symbol',
    header: () => <div className='text-xs text-left text-white'>Symbol</div>,
    cell: ({ row }) => {
      const symbol = row.original.symbol
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{symbol}</div>
    },
    size: 100,
  },
  {
    accessorKey: 'type',
    header: () => <div className='text-xs text-left text-white'>Type</div>,
    cell: ({ row }) => {
      const type = row.original.type
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{type}</div>
    },
    size: 100,
  },
  {
    accessorKey: 'available',
    header: () => <div className='text-xs text-left text-white'>Available Balance</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserBalanceForAssetByType
        ? table?.options?.meta?.getUserBalanceForAssetByType(row.original, AssetBalanceType.Available)
        : '0'
      return (
        <div className='border-0 text-left text-xs text-success font-semibold'>
          {formatNumber(balance, PRICE_DECIMALS)}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'locked',
    header: () => <div className='text-xs text-left text-white'>Locked Balance</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserBalanceForAssetByType
        ? table?.options?.meta?.getUserBalanceForAssetByType(row.original, AssetBalanceType.Locked)
        : '0'
      return (
        <div className='border-0 text-left text-xs text-warning font-semibold'>
          {formatNumber(balance, PRICE_DECIMALS)}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'borrowed',
    header: () => <div className='text-xs text-left text-white'>Borrowed</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserBalanceForAssetByType
        ? table?.options?.meta?.getUserBalanceForAssetByType(row.original, AssetBalanceType.Borrowed)
        : '0'
      return (
        <div className='border-0 text-left text-xs text-danger font-semibold'>
          {formatNumber(balance, PRICE_DECIMALS)}
        </div>
      )
    },
    size: 100,
  },
  {
    accessorKey: 'supplied',
    header: () => <div className='text-xs text-left text-white'>Supplied</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserBalanceForAssetByType
        ? table?.options?.meta?.getUserBalanceForAssetByType(row.original, AssetBalanceType.Supplied)
        : '0'
      return (
        <div className='border-0 text-left text-xs text-white-dark font-semibold'>
          {formatNumber(balance, PRICE_DECIMALS)}
        </div>
      )
    },
    size: 100,
  },
]
