import { useParams } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import { usePools } from '@/hooks/usePools'
import * as Sentry from '@sentry/react'
import _ from 'lodash'
import { LocalStorageKeys } from '@/constants/localstorage'

export const useOrderBookID = (disabled?: boolean, skipNavigation?: boolean) => {
  const { orderBookId: orderBookParam } = useParams()
  const { allPools, isError, loadingPools } = usePools()
  const storedPoolId = localStorage.getItem(LocalStorageKeys.SELECTED_POOL)

  const orderBookIDWithMostShares = useMemo(() => {
    if (!allPools.length) return undefined
    const poolWithHighestShares = _.maxBy(allPools, (pool) => Number(pool.poolShares.amount))
    return poolWithHighestShares?.uid
  }, [allPools.length])

  const getOrderBookID = useCallback((ticker: string) => {
    const [baseIsin, quoteIsin] = ticker.split('-')
    const orderBookId = baseIsin + '-' + quoteIsin
    return {
      baseIsin,
      quoteIsin,
      orderBookId,
      isReversed: orderBookId !== orderBookParam,
    }
  }, [])

  const findPool = useCallback((orderBookID: string) => {
    return allPools.find((pool) => pool.uid === orderBookID)
  }, [])

  const orderBookID = useMemo(() => {
    if (!orderBookParam && storedPoolId && findPool(storedPoolId)) {
      if (!skipNavigation) {
        window.history.pushState({}, '', `/trade/${storedPoolId}`)
      }

      return getOrderBookID(storedPoolId)
    } else if (orderBookParam && (findPool(orderBookParam) || disabled)) {
      return getOrderBookID(orderBookParam)
    } else if (
      import.meta.env.VITE_APP_DEFAULT_ORDERBOOK_ID &&
      findPool(import.meta.env.VITE_APP_DEFAULT_ORDERBOOK_ID)
    ) {
      if (!skipNavigation) {
        window.history.pushState({}, '', `/trade/${import.meta.env.VITE_APP_DEFAULT_ORDERBOOK_ID}`)
      }
      return getOrderBookID(import.meta.env.VITE_APP_DEFAULT_ORDERBOOK_ID)
    } else if (orderBookIDWithMostShares) {
      if (!skipNavigation) {
        window.history.pushState({}, '', `/trade/${orderBookIDWithMostShares}`)
      }
      return getOrderBookID(orderBookIDWithMostShares)
    } else {
      Sentry.captureMessage(`useOrderBookID: NO POOLS`, {
        extra: {
          numberOfPoolsAvailable: allPools.length,
          orderBookID_ENV: import.meta.env.VITE_APP_DEFAULT_ORDERBOOK_ID,
          orderBookID_param: orderBookParam,
          orderBookIDWithMostShares: orderBookIDWithMostShares,
        },
      })
      return {
        orderBookId: '',
        isReversed: false,
        baseIsin: '',
        quoteIsin: '',
      }
    }
  }, [orderBookIDWithMostShares, orderBookParam, disabled])

  return {
    orderBookId: orderBookID.orderBookId,
    isReversed: orderBookID.isReversed,
    baseIsin: orderBookID.baseIsin,
    quoteIsin: orderBookID.quoteIsin,
    isError,
    loadingPools,
  }
}
