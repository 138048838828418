import { Dispatch, SetStateAction, useMemo } from 'react'
import { useOrderBookID } from '@/hooks/useOrderBookID'
import { useAllAssets } from '@/hooks/useAsset'
import { useOrderBooks } from '@/hooks/useOrderBooks'
import { BondDetailsDescription } from '@/components/bondDetails/BondDetailsDescription'
import { BondDetailsInfo } from '@/components/bondDetails/BondDetailsInfo'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { AppTheme } from '@/utils/tradingview'

interface BondDetailsPanelProps {
  setShowBondDetails: Dispatch<SetStateAction<boolean>>
  appTheme?: AppTheme
}

export const BondDetailsPanel = ({ setShowBondDetails, appTheme }: BondDetailsPanelProps) => {
  const { orderBookId } = useOrderBookID()
  const { orderBooks } = useOrderBooks()
  const { allAssets } = useAllAssets()
  const { isTablet } = useBreakpoints()

  const baseAsset = useMemo(
    () => allAssets.find((asset) => asset.uid === orderBookId.split('-')[0]),
    [allAssets, orderBookId],
  )

  const orderBook = useMemo(
    () => orderBooks.find((orderBook) => orderBook.orderBookID === orderBookId),
    [orderBooks, orderBookId],
  )

  if (!orderBookId || !baseAsset) {
    return <div>No pair selected</div>
  }

  return (
    <div className={`w-full h-full relative z-20 ${appTheme === AppTheme.Light ? 'bg-white' : 'bg-black'}`}>
      <button
        className={`absolute font-bold top-2.5 right-3.5 z-40 bg-transparent ${appTheme === AppTheme.Light ? 'text-black' : 'text-white'} border-none text-2xl cursor-pointer`}
        onClick={() => setShowBondDetails(false)}
      >
        &#x2715;
      </button>

      {isTablet ? (
        <div className={`p-4 ${appTheme === AppTheme.Light ? 'text-black' : 'text-white'}`}>
          <div className={`my-8 text-start col-start-1 text-xs`}>Bond Details</div>
          <BondDetailsDescription baseAsset={baseAsset} orderBookId={orderBookId} />

          <BondDetailsInfo baseAsset={baseAsset} orderBook={orderBook} />
        </div>
      ) : (
        <div
          className={`w-full h-full grid grid-cols-4 grid-rows-1 items-center gap-8 ${appTheme === AppTheme.Light ? 'text-black' : 'text-white'}`}
        >
          <div className={'w-full h-full text-start mt-0 col-start-1 text-xs'}>Bond Details</div>
          <BondDetailsDescription baseAsset={baseAsset} orderBookId={orderBookId} />

          <BondDetailsInfo baseAsset={baseAsset} orderBook={orderBook} />
        </div>
      )}
    </div>
  )
}
