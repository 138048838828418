import dayjs from 'dayjs'
import { IoCloseOutline } from 'react-icons/io5'
import {
  createNotification,
  getContentForType,
  NotificationItem,
  NotificationStatus,
  NotificationType,
} from '@/utils/notificationUtils'
import { MdOutlineContentCopy } from 'react-icons/md'

export interface NotificationItemProps {
  item: NotificationItem
  onNotificationClose: () => void
}

const getStatusBar = (type: NotificationStatus) => {
  switch (type) {
    case NotificationStatus.SUCCESS:
      return <div id={'typeBar'} className={'h-full !w-[5px] bg-success rounded-l'}></div>
    case NotificationStatus.WARNING:
      return <div id={'typeBar'} className={'h-full !w-[5px] bg-warning rounded-l'}></div>
    case NotificationStatus.INFO:
      return <div id={'typeBar'} className={'h-full !w-[5px] bg-info rounded-l'}></div>
    case (NotificationType.SIMPLE, NotificationStatus.ERROR):
    default:
      return <div id={'typeBar'} className={'h-full !w-[5px] bg-danger rounded-l'}></div>
  }
}

export const NotificationItemComponent = ({ item, onNotificationClose }: NotificationItemProps) => {
  const date = dayjs(item.timestamp).format('MMMM D, YYYY - HH:mm:ss')

  const onNotificationCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(item.params)).finally()
    createNotification('Copied to clipboard', 'Notification content was copied', NotificationType.DISPOSABLE)
  }

  return (
    <div id={'NotificationItemComponent'} className={'relative flex flex-row bg-dark rounded mt-2'}>
      <div>{getStatusBar(item.notificationStatus)}</div>
      <div className={'w-full flex p-2'}>
        <div className={'w-full justify-between'}>
          <div className={'flex flex-row'}>
            <h1 className='w-full font-bold text-sm text-white'>{item.title}</h1>
            {item.notificationType != NotificationType.DISPOSABLE && (
              <MdOutlineContentCopy size={16} className={'cursor-pointer'} onClick={() => onNotificationCopy()} />
            )}
            <IoCloseOutline size={16} className={'cursor-pointer'} onClick={() => onNotificationClose()} />
          </div>

          <div className={'text-sm'}>{getContentForType(item.notificationType, item.params)}</div>
          <div className={'pt-2 text-xs text-white text-right'}>{date}</div>
        </div>
      </div>
    </div>
  )
}
