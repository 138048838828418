import { gql } from 'graphql-request'

export const USER_BALANCES = gql`
  subscription UserBalancesUpdated($userUid: String!) {
    userBalancesUpdated(userUID: $userUid) {
      total {
        assetID
        amount
        sortAmount
      }
      available {
        assetID
        amount
        sortAmount
      }
      locked {
        assetID
        amount
        sortAmount
      }
      borrowed {
        assetID
        amount
        sortAmount
      }
      supplied {
        assetID
        amount
        sortAmount
      }
      liquidationPrices {
        assetID
        price
      }
      maxBorrow {
        assetID
        amount
        sortAmount
      }
      maxBuyPower {
        assetID
        amount
        sortAmount
      }
    }
  }
`
