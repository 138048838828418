import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { KEYBOARD_SHORTCUTS } from '@/types/shortcuts'
import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'

interface ShortcutContextProps {
  shortcut: string
  tradeKey: number
  orderBookKey: number
  reset: () => void
}

const ShortcutContext = createContext<ShortcutContextProps | undefined>(undefined)

const ShortcutProvider = ({ children }: PropsWithChildren) => {
  const user = useFirebaseAuth()
  const navigate = useNavigate()
  const [shortcut, setShortcut] = useState('')
  const [tradeKey, setTradeKey] = useState(0)
  const [orderBookKey, setOrderBookKey] = useState(0)

  const reset = () => {
    setTradeKey(0)
    setOrderBookKey(0)
    setShortcut('')
  }

  useEffect(() => {
    if (!user) return

    const handleShortcut = (
      shortcut: string,
      path: string,
      increaseTradeKey: boolean = true,
      increaseOrderBookKey: boolean = false,
    ) => {
      setShortcut(shortcut)

      if (increaseTradeKey) setTradeKey((prev) => prev + 1)
      if (increaseOrderBookKey) setOrderBookKey((prev) => prev + 1)

      if (!window.location.pathname.includes('/trade')) {
        navigate(path)
      }
    }

    const shortcutMap = {
      'Shift+B': () => handleShortcut(KEYBOARD_SHORTCUTS.MARKET_ORDER_BUY, '/trade'),
      'Shift+S': () => handleShortcut(KEYBOARD_SHORTCUTS.MARKET_ORDER_SELL, '/trade'),
      'Shift+O': () => handleShortcut(KEYBOARD_SHORTCUTS.LIMIT_ORDER_BUY, '/trade'),
      'Shift+P': () => handleShortcut(KEYBOARD_SHORTCUTS.LIMIT_ORDER_SELL, '/trade'),
      'Ctrl+Enter': () => handleShortcut(KEYBOARD_SHORTCUTS.SUBMIT_ORDER, '/trade', false, false),
      'Shift+C': () => handleShortcut(KEYBOARD_SHORTCUTS.CENTER_ORDER_BOOK, '/trade', false, true),
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      const keyCombination = [
        event.shiftKey ? 'Shift' : '',
        event.altKey ? 'Alt' : '',
        event.ctrlKey ? 'Ctrl' : '',
        event.key,
      ]
        .filter(Boolean)
        .join('+')

      console.log('handleKeyDown', keyCombination)

      if (keyCombination in shortcutMap) {
        ;(shortcutMap as Record<string, () => void>)[keyCombination]()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [navigate])

  return (
    <ShortcutContext.Provider value={{ shortcut, tradeKey, orderBookKey, reset }}>{children}</ShortcutContext.Provider>
  )
}

const useShortcuts = () => {
  const context = useContext(ShortcutContext)
  if (context === undefined) {
    throw new Error('useShortcuts must be used within a ShortcutProvider')
  }
  return { ...context, resetShortcuts: context.reset }
}

export { ShortcutProvider, useShortcuts }
