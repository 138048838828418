import { gql } from 'graphql-request'

export const PRICES_UPDATES = gql`
  subscription PricesUpdates {
    pricesUpdates {
      assetID
      price
    }
  }
`
