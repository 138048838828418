import { useQuery } from '@tanstack/react-query'
import { FloatPrice, getSdk } from '@/graphql/generated/graphql-request'
import { graphQLClient, wsClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { useFirebaseAuth } from '@/utils/firebaseAuthProvider'
import { useEffect } from 'react'
import { queryClient } from '@/services/api'
import { PRICES_UPDATES } from '@/services/graphql/subscriptions/assets'

export const useAssetPrices = () => {
  const user = useFirebaseAuth()

  const { isLoading, data } = useQuery({
    queryKey: ['GET_ASSET_PRICES'],
    enabled: !!user,
    queryFn: async () =>
      getSdk(graphQLClient)
        .GetAllPrices()
        .then((result) => result.getAllPrices)
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on GetAllPrices'
          createNotification('Error on GetAllPrices', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
  })

  return {
    isLoading: isLoading,
    prices: data,
  }
}

export const usePriceChangedSubscription = () => {
  const user = useFirebaseAuth()
  useEffect(() => {
    const subscription = wsClient.subscribe(
      {
        operationName: 'PricesUpdates',
        query: PRICES_UPDATES,
      },
      {
        next: ({ data }: { data: { pricesUpdates: [FloatPrice] } | null }) => {
          if (!data) return

          if (data.pricesUpdates) {
            queryClient.setQueryData(['GET_ASSET_PRICES'], data.pricesUpdates)
          }
        },
        error: (err) => {
          console.log('usePriceChangedSubscription subscription error', err)
        },
        complete: () => {},
      },
    )

    // Cleanup function to unsubscribe
    return () => {
      console.log('usePriceChangedSubscription - Unsubscribing from usePriceChangedSubscription')
      subscription()
    }
  }, [user?.uid])
}
