import { FormEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Asset, AssetType, getSdk } from '@/graphql/generated/graphql-request'
import { cleanInput, removeLettersForInput } from '@/utils/inputUtils'
import { useAllAssets } from '@/hooks/useAsset'
import { useMutation } from '@tanstack/react-query'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { queryClient } from '@/services/api'
import dayjs from 'dayjs'

type Inputs = {
  baseAsset: string
  quoteAsset: string
  g: string
  poolMaturity: string
  priceDisplayMultiplier: string
  tickSize: string
  swapFee: string
}

export const CreateLiquidityPoolForm = () => {
  const { allAssets } = useAllAssets()
  const [selectedbaseAsset, setSelectedbaseAsset] = useState(allAssets[0]?.symbol ?? '')
  const [selectedquoteAsset, setSelectedquoteAsset] = useState(allAssets[1]?.symbol ?? '')

  const nonPoolAssets = allAssets.filter((asset: Asset) => asset?.type !== AssetType.PoolShare)

  const validationSchema = z
    .object({
      baseAsset: z.string().min(1, 'Please select a bond'),
      quoteAsset: z.string().min(1, 'Please select a bond'),
      g: z.string().optional(),
      poolMaturity: z.string().optional(),
      priceDisplayMultiplier: z.string().min(1, 'Please insert a value for price display multiplier'),
      tickSize: z.string().min(1, 'Please insert a value for tick size'),
      swapFee: z.string().optional(),
    })
    .refine((data) => data.baseAsset !== data.quoteAsset, {
      message: 'Please select different bonds',
      path: ['quoteAsset'],
    })
    .refine(
      (data) => {
        const hasAmm = Boolean(data.g && data.g.length > 0 && data.poolMaturity && data.poolMaturity.length > 0)
        const hasSwapFee = Boolean(data.swapFee && data.swapFee.length > 0)

        // Either has AMM settings or swap fee, but not both
        return (hasAmm && !hasSwapFee) || (!hasAmm && hasSwapFee)
      },
      {
        message: 'Please provide either both G and Pool Maturity OR Swap Fee',
        path: ['swapFee'],
      },
    )

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(validationSchema),
  })

  const handleInputChange = (e: FormEvent<HTMLInputElement>, field: keyof Inputs) => {
    const inputValue = e.currentTarget.value
    const sanitizedInput = removeLettersForInput(inputValue)
    setValue(field, sanitizedInput)
  }

  const mutation = useMutation({
    mutationFn: (input: {
      input: {
        ammInput?: {
          g: number
          poolMaturity: number
        }
        prodProp?: {
          swapFee: number
        }
        baseUID: string
        priceDisplayMultiplier: number
        quoteUID: string
        tickSize: number
      }
    }) =>
      getSdk(graphQLClient)
        .CreateOrderBook(input)
        .then(() => {
          createNotification(
            'Create Pool ',
            {
              baseAssetID: input.input.baseUID,
              quoteAssetID: input.input.quoteUID,
            },
            NotificationType.CREATE_ORDER_BOOK,
          )
        })
        .catch((err) => {
          const message = err?.message ?? 'Error on CreateOrderBook'
          createNotification('Error on CreateOrderBook', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['POOLS'] }).finally()
      queryClient.invalidateQueries({ queryKey: ['ASSETS'] }).finally()
    },
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    // Extract and prepare values
    const baseAssetID = data.baseAsset
    const quoteAssetID = data.quoteAsset
    const g = data.g?.length > 0 ? cleanInput(data.g) : null
    const poolMaturity = data.poolMaturity?.length > 0 ? dayjs(data.poolMaturity).unix().toString() : null
    const priceDisplayMultiplier = cleanInput(data.priceDisplayMultiplier)
    const tickSize = cleanInput(data.tickSize)
    const swapFee = data.swapFee?.length > 0 ? cleanInput(data.swapFee) : null

    // Create the nested mutation input structure
    const mutationInput = {
      input: {
        ...(g && poolMaturity
          ? {
              ammInput: {
                g: Number(g),
                poolMaturity: Number(poolMaturity),
              },
            }
          : {}),
        ...(swapFee
          ? {
              prodProp: {
                swapFee: Number(swapFee),
              },
            }
          : {}),
        baseUID: baseAssetID,
        priceDisplayMultiplier: Number(priceDisplayMultiplier),
        quoteUID: quoteAssetID,
        tickSize: Number(tickSize),
      },
    }

    console.log('create pool mutation input', mutationInput)
    mutation.mutate(mutationInput)
  }

  return (
    <div className='w-1/3'>
      <div className='panel grid grid-cols-1 dark:bg-card h-full items-center'>
        <h1 className='col-span-1 dark:text-white text-lg font-semibold pb-8'>Create OrderBook</h1>
        <form onSubmit={handleSubmit(onSubmit)} className='w-full col-span-1 grid grid-cols-3 gap-4'>
          <div className='flex flex-col'>
            <label className='text-left'>Base Asset</label>
            <select
              {...register('baseAsset', { required: true })}
              value={selectedbaseAsset}
              onChange={(e) => setSelectedbaseAsset(e.target.value)}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            >
              {nonPoolAssets.map((asset: Asset) => (
                <option key={'create_pool_option_first' + asset.uid} value={asset?.uid}>
                  {asset?.symbol}
                </option>
              ))}
            </select>
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.baseAsset ? errors.baseAsset.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Quote Asset</label>
            <select
              {...register('quoteAsset', { required: true })}
              value={selectedquoteAsset}
              onChange={(e) => setSelectedquoteAsset(e.target.value)}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            >
              {nonPoolAssets.map((asset: Asset) => (
                <option key={'create_pool_option_second' + asset.uid} value={asset?.uid}>
                  {asset?.symbol}
                </option>
              ))}
            </select>
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.quoteAsset ? errors.quoteAsset.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Pool Maturity</label>
            <input
              id='poolMaturity'
              type='date'
              {...register('poolMaturity', { required: false })}
              //onInput={(e) => handleInputChange(e, 'poolMaturity')}
              className='w-full border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.poolMaturity ? errors.poolMaturity.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Price Display Multiplier</label>
            <input
              id='priceDisplayMultiplier'
              {...register('priceDisplayMultiplier', { required: true })}
              onInput={(e) => handleInputChange(e, 'priceDisplayMultiplier')}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.priceDisplayMultiplier ? errors.priceDisplayMultiplier.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>G</label>
            <input
              id='g'
              {...register('g', { required: false })}
              onInput={(e) => handleInputChange(e, 'g')}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.g ? errors.g.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Tick Size</label>
            <input
              id='tickSize'
              {...register('tickSize', { required: true })}
              onInput={(e) => handleInputChange(e, 'tickSize')}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
            <span className='text-red-500 text-end' style={{ minHeight: '1rem' }}>
              {errors.tickSize ? errors.tickSize.message : '\u00a0'}
            </span>
          </div>

          <div className='flex flex-col'>
            <label className='text-left'>Swap Fee</label>
            <input
              id='swapFee'
              {...register('swapFee', { required: false })}
              onInput={(e) => handleInputChange(e, 'swapFee')}
              className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest text-right outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
            />
          </div>

          {/* Separate error message with col-span-2 */}
          <div className='col-span-2 flex justify-start items-center pt-4'>
            <span className='text-red-500 text-start block w-full' style={{ minHeight: '1rem' }}>
              {errors.swapFee ? errors?.swapFee?.message : '\u00a0'}
            </span>
          </div>

          <div className='col-span-3 flex items-center'>
            <button
              type='submit'
              className='w-full btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] my-4'
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
