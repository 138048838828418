// @ts-nocheck
import { depositAddress, USDC } from '@/configs/tokens'
import { getSdk } from '@/graphql/generated/graphql-request'
import { queryClient } from '@/services/api'
import { graphQLClient } from '@/services/graphql'
import { createNotification, NotificationStatus, NotificationType } from '@/utils/notificationUtils'
import { formatNumber } from '@/utils/numberUtils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useState } from 'react'
import { BiRightArrowAlt, BiCaretDown, BiX } from 'react-icons/bi'
import Lottie from 'react-lottie'
import loadingAnimation from '../../assets/lottie/loading.json'

interface Props {
  onClose: () => void
  title: string
  from: {
    chainId?: number
    address?: string
    chain: string
    chainLogo: string
    asset: string
    assetLogo: string
  }
  to: {
    chainId?: number
    address?: string
    chain: string
    chainLogo: string
    asset: string
    assetLogo: string
  }
  maxBalance: string
  amount: string
  setAmount: (a: string) => void
  buttonText: string
  onSubmit: () => void
  loading: boolean
  isDeposit: boolean
  decimals: number
  depositStage?: 0 | 1 | 2 // 0: normal; 1: tx hash retrieved, deposit pending; 2: deposit success
}

const BridgeModal = ({
  onClose,
  onSubmit,
  loading,
  title,
  from,
  to,
  amount,
  setAmount,
  maxBalance,
  buttonText,
  isDeposit,
  decimals,
  depositStage,
}: Props) => {
  const web3modal = useWeb3Modal()

  const { data: estimatedGasResult } = useQuery({
    queryKey: [`GAS_ESTIMATE_${to.chainId}_${to.address}_${amount}`],
    queryFn: async () =>
      getSdk(graphQLClient)
        .EstimateGas({
          estimateGasInput: {
            amount: String(Number(amount) * 10 ** decimals),
            chainID: String(to.chainId || 1),
            denom: 'USDC',
            toHex: to.address || '',
            network: 'eth',
          },
        })
        .then((result) => result.estimateGas)
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on EstimateGas'
          createNotification('Error on EstimateGas', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    placeholderData: 0,
    enabled: !isDeposit && !!to.chainId && !!to.address && !!Number(amount),
    refetchOnMount: true,
  })
  const gas = !isDeposit && !!to.chainId && !!to.address && !!Number(amount) ? estimatedGasResult : 0

  const [isRetrievingDeposit, setIsRetrievingDeposit] = useState(false)
  const [txHash, setTxHash] = useState('')

  const depositMutation = useMutation({
    mutationFn: () =>
      getSdk(graphQLClient)
        .ExternalDeposit({
          deposit: {
            chainID: String(from?.chainId || 1),
            txHex: txHash,
            fromHex: from?.address || '',
            toHex: depositAddress[from?.chainId || 1],
            tokenContractHex: USDC[from?.chainId || 1].address,
            network: 'eth',
          },
        })
        .then(() => {
          // params.selectedAsset, params.text, params.amount, params.isAdding
          createNotification(
            'Deposit',
            `You successfully retrieved your USDC on Dora. You can start trading now.`,
            NotificationType.SIMPLE,
          )
        })
        .catch((err) => {
          const message = err?.response?.errors[0]?.message ?? 'Error on ExternalDeposit'
          createNotification('Error on ExternalDeposit', message, NotificationType.SIMPLE, NotificationStatus.ERROR)
        }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['USER_BALANCES'] }).finally()
    },
  })

  return (
    <div className={'relative'} onClick={depositStage === 1 ? () => null : onClose}>
      <div className={'z-50 fixed bg-black/80 w-full h-full top-0 left-0 right-0 bottom-0'}>
        <div
          className={
            'w-[420px] p-8 bg-panel flex flex-col items-start gap-2 border border-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg'
          }
          onClick={(e) => e.stopPropagation()}
        >
          <div className={'text-white text-xl font-semibold mb-4 self-center'}>
            {isRetrievingDeposit ? 'Retrieve Previous Deposit' : title}
          </div>
          {depositStage === 1 ? null : (
            <button onClick={onClose} className='absolute top-6 right-6'>
              <BiX size={32} />
            </button>
          )}
          {depositStage === 1 || depositStage === 2 ? (
            <>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loadingAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                speed={1.5}
                width={300}
                height={200}
              />
              <div className={'text-white text-lg font-semibold mb-4 self-center text-center'}>
                Congratulations. Your Deposit is being processed. Once it is initiated on-chain, you can click
                "Continue" to close the window.
              </div>
              {depositStage === 1 ? (
                <div className={'text-white text-lg font-semibold mb-4 self-center text-center'}>
                  Please do not refresh the page or click the "Back" or "Close" button of your browser
                </div>
              ) : (
                <button
                  type='button'
                  className='btn w-full bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
                  onClick={onClose}
                >
                  Continue
                </button>
              )}
            </>
          ) : (
            <>
              <div className={'flex justify-between items-center gap-4'}>
                <div className={'flex flex-col items-start gap-2 mb-4'}>
                  <span className={'text-sm'}>Source</span>
                  <div
                    className={`flex items-center gap-2${isDeposit ? ' cursor-pointer' : ''}`}
                    onClick={isDeposit ? () => web3modal.open({ view: 'Networks' }) : undefined}
                  >
                    <img alt='ethereumIcon' className={'w-8 h-8 p-0 m-0'} src={from.chainLogo} />
                    <span className={'text-base text-white'}>{from.chain}</span>
                    {isDeposit ? <BiCaretDown size={24} /> : null}
                  </div>
                </div>
                <BiRightArrowAlt size={32} />
                <div className={'flex flex-col items-start gap-2 mb-4'}>
                  <span className={'text-sm'}>Destination</span>
                  <div
                    className={`flex gap-2 items-center${!isDeposit ? ' cursor-pointer' : ''}`}
                    onClick={!isDeposit ? () => web3modal.open({ view: 'Networks' }) : undefined}
                  >
                    <img alt='ethereumIcon' className={'w-8 h-8 p-0 m-0'} src={to.chainLogo} />
                    <span className={'text-base text-white'}>{to.chain}</span>
                    {!isDeposit ? <BiCaretDown size={24} /> : null}
                  </div>
                </div>
              </div>
              <div className={'flex flex-col items-start gap-2 mb-4'}>
                <span className={'text-sm'}>Asset</span>
                <div className='flex gap-4 items-center'>
                  <img alt='usdcIcon' className={'w-8 h-8 p-0 m-0'} src={from.assetLogo} />
                  <span className={'text-base text-white'}>{from.asset}</span>
                </div>
              </div>
              {isRetrievingDeposit ? (
                <div className={'flex flex-col items-start gap-2 mb-4 w-full'}>
                  <span className={'text-sm'}>Transaction Hash</span>
                  <input
                    className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                    placeholder='0x...'
                    value={txHash}
                    onChange={(e) => setTxHash(e.target.value)}
                  />
                </div>
              ) : (
                <>
                  <div className={'flex flex-col items-start gap-2 mb-4 w-full'}>
                    <span className={'text-sm'}>Amount</span>
                    <input
                      className='border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
                      placeholder='0.000000'
                      type='number'
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <div className='flex justify-between w-full items-start'>
                      <div className={'text-sm'}>Balance: {formatNumber(maxBalance, decimals)}</div>
                      <button
                        type='button'
                        onClick={() => setAmount(maxBalance)}
                        className='btn px-2 py-0 bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
                      >
                        Max
                      </button>
                    </div>
                  </div>
                  <div className={'flex flex-col gap-2 my-4 w-full'}>
                    <div className='flex justify-between items-center'>
                      <span className={'text-base'}>Expected {isDeposit ? 'Deposit' : 'Withdraw'} Amount</span>
                    </div>
                    <div className='flex justify-between items-center'>
                      <span className={'text-base'}>{isDeposit ? 'Equity' : 'Requested Withdrawal Amount'}</span>
                      <span className={'text-base text-white'}>{formatNumber(amount, 2, 'en', 'USD', true)}</span>
                    </div>
                    {!isDeposit && (
                      <div className='flex justify-between items-center'>
                        <span className={'text-base'}>Estimated Gas Fees</span>
                        <span className={'text-base text-white'}>
                          {formatNumber(gas || 0, (gas || 0) > 0 ? 4 : 2, 'en', 'USD', true)}
                        </span>
                      </div>
                    )}
                    <div className='flex justify-between items-center'>
                      <span className={'text-base'}>{isDeposit ? 'Buying Power' : 'Total Amount'}</span>
                      <span className={'text-base text-white'}>
                        {formatNumber(Number(amount) - (gas || 0), (gas || 0) > 0 ? 4 : 2, 'en', 'USD', true)}
                      </span>
                    </div>
                    <div className='flex justify-between items-center'>
                      <span className={'text-base'}>Estimated Time</span>
                      <span className={'text-base text-white'}>{'< 30 minutes'}</span>
                    </div>
                  </div>
                </>
              )}

              <button
                type='button'
                className='btn w-full bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white border-0 shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
                onClick={
                  isDeposit && isRetrievingDeposit
                    ? () => {
                        depositMutation.mutate()
                      }
                    : onSubmit
                }
                disabled={loading}
              >
                {isRetrievingDeposit ? 'Retrieve Deposit' : buttonText}
              </button>
              {isDeposit && (
                <button
                  type='button'
                  className='btn w-full text-white border-0 hover:text-primary shadow-none'
                  onClick={() => setIsRetrievingDeposit((i) => !i)}
                  disabled={loading}
                >
                  {isRetrievingDeposit ? 'Start a New Deposit' : 'Retrieve Previous Deposit'}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default BridgeModal
